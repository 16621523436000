import { Row, Col, Image } from 'antd';
import React from 'react';
import './HeaderApp.scss';

const HeaderApp = () => {
  return (
    <Row align={"bottom"} className="header-app">
      <Col span={24}>
        <Image
          src={require('../../Images/header_devfest.png')}
          alt="Header Keepcod Devfest"
          preview={false}
        />
      </Col>
    </Row >
  )
};

export default HeaderApp;