import { Row, Col, Button } from 'antd';
import './FooterApp.scss';
import WeLoveDevLink from '../SocialNetworks/WeLoveDev/WeLoveDev';
import LinkedInLink from '../SocialNetworks/LinkedIn/LinkedIn';
import TwitterLink from '../SocialNetworks/Twitter/Twitter';
import InstagramLink from '../SocialNetworks/Instagram/Instagram';

const FooterApp = () => {
  return (
    <Row className='container-footer'>
      <Col span={24}>
        <Row className='row-footer social-network'>
          <Col>
            <WeLoveDevLink />
          </Col>
          <Col>
            <LinkedInLink />
          </Col>
          <Col>
            <InstagramLink />
          </Col>
          <Col>
            <TwitterLink />
          </Col>
        </Row>
        <Row className='row-footer mention-legal'>
          <Col>
            <Button type="link" href='https://keepcod.fr/mentions-legales/' target='_blank' className='button-mentions-legal'>mentions légales</Button>
          </Col>
        </Row>
      </Col>      

    </Row>
  );
};

export default FooterApp;