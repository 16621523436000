import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { ReactComponent as winkedInIcon } from './logojaune_in.svg';

const LinkedInLink = () => (
    <Button type="link" href='https://www.linkedin.com/company/14069809' target='_blank'>
        <Icon component={winkedInIcon} className='social-logo' />
    </Button>
);

export default LinkedInLink;