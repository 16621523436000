import ReactPlayer from "react-player/youtube";
import { Row } from "antd";

import "./VideoPresentation.scss"

const VideoPresentation = () => {
  return (
    <>
      <Row className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url='https://www.youtube.com/watch?v=s-wcTv7TsIk'
          width='100%'
          height='100%'
        />
      </Row>
    </>
  );
};

export default VideoPresentation;