import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { ReactComponent as weLoveDevIcon } from "./wld.svg";

const WeLoveDevLink = () => (
    <Button type="link" href="https://welovedevs.com/app/fr/company/keepcod" target='_blank'>
        <Icon className='we-love-dev' component={weLoveDevIcon} />
    </Button>
);

export default WeLoveDevLink;