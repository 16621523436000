import { Row, Col, Typography, Button, Image } from 'antd';
import VideoPresentation from './Components/VideoPresentation/VideoPresentation';
import CustomCard from './Components/CustomCard/CustomCard';
import StarIcon from './Components/Atoms/Star/Star';
import React, { useState } from "react";
import './ContentApp.scss';

const { Text, Paragraph } = Typography;

const App = () => {

  const [isLoading, setIsLoading] = useState(true);
  
  function detectScreenSize() {
    const screenWidth = window.innerWidth;

    if (screenWidth > 1024) {
      window.location.href = "https://keepcod.fr";
      setIsLoading(false);
    }
  }

  window.addEventListener('resize', detectScreenSize);

  detectScreenSize();

  return (
    isLoading ? (
    <>
      <Row className='row-spacing'>
        <Col span={24} >
          <VideoPresentation />
        </Col>
      </Row>
      <Row className='row-spacing row-functionality'>
        <Col span={24}>
          <CustomCard>
            <Paragraph>
              Notre live-action
            </Paragraph>
            <Paragraph className='video-description-colored-text'>
              Recommandé 99% <Text>- 2023 - 1m48s</Text>
            </Paragraph>
            <Paragraph>
              Découvrez qui nous sommes à travers
              la vidéo de notre séminaire
              qui reflète nos principes et nos valeurs ...
            </Paragraph>
            <Paragraph>
              Genre : aventure, famille, comédie
              <br />
              Thème : piraterie, amitié, liberté, professionnel
              <br />
              Réalisation : depuis 2013 - en cours
            </Paragraph>
            <Paragraph>
              Distribution :  tout l’équipage Keepcod
            </Paragraph>
            <Text>
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
            </Text>
          </CustomCard>
        </Col>
      </Row>
      <Row className='row-spacing row-functionality'>
        <Col span={24}>
          <CustomCard>
            <Paragraph>Keepcod ?</Paragraph>
            <Paragraph>Pas de numéros, que des héros. 🦸</Paragraph>
            <Paragraph>Une équipe soudée, de l'entraide, de la bienveillance.<br/>
            Des projets variés et souvent éthiques. 🌿</Paragraph>
            <Paragraph>Un accompagnement personnalisé et des technos mythiques.<br/>
            + de formations que de réunions 🧠</Paragraph>
            <Paragraph>Retrouvez sur notre stand ceux qui font de Keepcod une entreprise différente. ⛵</Paragraph>
          </CustomCard>
        </Col>
      </Row>
      <Row className='row-spacing'>
        <Col span={24}>
          <Text className='title-centered'>
            Dans le top en ce moment :
          </Text>
        </Col>
      </Row>
      <Row className='row-spacing'>
        <Col span={24}>
          <Image
            src={require('./Images/testpersoop.png')}
            alt="Jeu de personnalité"
            preview={false}
            onClick={() => window.open('https://personality-game.keepcod.com/', '_blank')}
            className='home-image'
          />
        </Col>
      </Row>
      <Row className='row-spacing'>
        <Col span={24}>
          <CustomCard>
            <Text>
              Découvrez à quel personnage
              vous correspondez & RETROUVEZ VOTRE ÉQUIPAGE
              à travers la mer agitée de la cité des congrès !
            </Text>
          </CustomCard>
        </Col>
      </Row>
      <Row className='button-row-spacing'>
        <Col span={24}>
          <Button type="primary" href='https://personality-game.keepcod.com/' className='primary-button' target='_blank'>
            Je fais le test !
          </Button>
        </Col>
      </Row>
      <Row className='row-spacing'>
        <Col span={24}>
          <Image
            src={require('./Images/presentationJeu.png')}
            alt="Présentation du jeu"
            preview={false}
            className='home-image'
          />
        </Col>
      </Row>
      <Row className='row-spacing row-functionality'>
        <Col span={24}>
          <CustomCard>
            <Paragraph>
              SAUVEZ LA CITÉ DES CONGRÈS !
            </Paragraph>
            <Text>
              Désamorçez, en équipe, la bombe qui menace d’exploser 💣 JEU VR 🥽
            </Text>
          </CustomCard>
        </Col>
      </Row>
      <Row className='row-spacing'>
        <Col span={24} className='lot-jeu'>
          <Image
            src={require('./Images/teambuilding.png')}
            alt="Logo Keepcod"
            preview={false}
            className='home-image'
          />
          <Image
            src={require('./Images/goodies.png')}
            alt="Logo Keepcod"
            preview={false}
            className='home-image'
          />
          <Image
            src={require('./Images/reductions.png')}
            alt="Logo Keepcod"
            preview={false}
            className='home-image'
          />
        </Col>
      </Row>
      <Row className='row-spacing row-functionality'>
        <Col span={24}>
          <CustomCard>
            <Text>
              Les trésors que vous pouvez amasser en participant à nos jeux ....
            </Text>
          </CustomCard>
        </Col>
      </Row>
      <Row className='row-spacing-title-left'>
        <Col span={12} className='container-title-left'>
          <Text className='title-left-side'>Nos sélections</Text>
        </Col>
      </Row>
      <Row className='row-spacing'>
        <Col span={24}>
          <Image
            src={require('./Images/planStand.png')}
            alt="Position du stand de Keepcod"
            preview={false}
            className='home-image'
          />
        </Col>
      </Row>
      <Row className='row-spacing row-functionality'>
        <Col span={24}>
          <CustomCard>
            <Text>
              Retrouvez nous sur notre stand 🙄
            </Text>
          </CustomCard>
        </Col>
      </Row>
      <Row className='row-spacing equipe-keepcod'>
        <Col className='hat-one-peace'>
          <Image 
            src={require('./Images/Onepiece.png')}
            alt="Chapeau de paille one piece "
            preview={false}
          />
        </Col>
        <Image
          src={require('./Images/equipage_x_Keepcod.jpg')}
          alt="L'équipe de Keepcod"
          preview={false}
          className='home-image'
        />
      </Row>
      <Row className='last-button-row-spacing'>
        <Col span={24}>
          <Button type="primary" href='https://keepcod.fr/nos-jobs-keepcod/' className='primary-button' target='_blank'>
            Découvrez nos jobs
          </Button>
        </Col>
      </Row>
    </>
        ) : null
  );
}

export default App;
