import { Row, Col } from 'antd';
import './CustomCard.scss';

const CustomCard = (props) => {
  return (
    <Row className='container-custom-card'>
      <Col span={24} className='container-text-card'>
        {props.children}
      </Col>
    </Row>
  );
};

export default CustomCard;