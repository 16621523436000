import Icon from '@ant-design/icons';
import { Button } from "antd";
import { ReactComponent as twitter } from './logojaune_twitter.svg';

const TwitterLink = () => (
    <Button type="link" href='https://twitter.com/Keepcod_IT' target='_blank'>
        <Icon component={twitter} className='social-logo' />
    </Button>
)

export default TwitterLink;