import React from 'react';
import ReactDOM from 'react-dom/client';
import './GlobalStyles/_body.scss';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

import HeaderApp from './Components/HeaderApp/HeaderApp';
import FooterApp from './Components/FooterApp/FooterApp';
import ContentApp from './ContentApp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* TODO: Add the theme inside a new file to import it here */}
    <ConfigProvider
          theme={{
            token: {
              //Style Global pour les composants AntDesign
              colorPrimary: '#FCCC1C',
              colorPrimaryActive : '#FCCC1C',
              colorPrimaryBorder : '#FCCC1C',
              colorPrimaryHover : '#FCCC1C',
              fontFamily: ['Manrope', 'sans-serif']
            },
            components: {
              Button: {
                controlHeight: 'auto',
                borderRadius: '20px',
                borderRadiusLG: '20px',
              },
            },
          }}
        >
      <HeaderApp/>
      <ContentApp/>
      <FooterApp/>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
