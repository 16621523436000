import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { ReactComponent as InstagramIcon } from "./logojaune_insta.svg";

const InstagramLink = () => (
    <Button type="link" href="https://www.instagram.com/keepcod_/" target='_blank'>
        <Icon component={InstagramIcon} className='social-logo'  />
    </Button>
);

export default InstagramLink;